import { Modal } from "react-bootstrap";
import "./LoginModal.css";
import Ledger from "../assets/Ledger.png";
import MaiarApp from "../assets/MaiarApp.png";
import MaiarExtension from "../assets/MaiarExtension.png";
import WebWallet from "../assets/WebWallet.png";
import { WalletConnectLoginButton, ExtensionLoginButton, LedgerLoginButton, WebWalletLoginButton } from "@elrondnetwork/dapp-core/UI";

const LoginModal: React.FC<{
  show: any;
  handleClose: any;
}> = ({ show, handleClose }) => {
  return (
    <Modal dialogClassName="login-modal" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton closeVariant="white" className="border-0 pb-0 align-items-baseline">
        <Modal.Title>
          <div className="modal-title">
            <h1>VICTORIA LAND</h1>
            <p>Please select your login method</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="connect-buttons">
        <WalletConnectLoginButton>
          <img style={{ width: "20px" }} className="me-2" alt="Maiar App" src={MaiarApp} /> Maiar App
        </WalletConnectLoginButton>
        <ExtensionLoginButton>
          <img style={{ width: "30px" }} className="me-2" alt="Maiar Extension" src={MaiarExtension} />
          Maiar Extension
        </ExtensionLoginButton>
        <LedgerLoginButton>
          <img style={{ width: "48px" }} className="me-0" alt="Ledger" src={Ledger} />
          Ledger
        </LedgerLoginButton>
        <WebWalletLoginButton callbackRoute="/">
          <img style={{ width: "30px" }} className="me-2" alt="Web Wallet" src={WebWallet} />
          Web Wallet
        </WebWalletLoginButton>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
