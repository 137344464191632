export const formatAmount = (input: number | BigInt, denominate: number = 18, decimals: number = 2) => {
  if (!input) return 0;

  const value = typeof input === "number" ? input : Number(input);

  input = value / 10 ** denominate;
  return Number(input.toFixed(decimals)).toLocaleString("EN", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};

export function bufferToHex(str: string) {
  return Buffer.from(str, "base64").toString("hex");
}

export function stringToHex(str: string) {
  return Buffer.from(str).toString("hex");
}

export function hexToDecimal(hexString: string) {
  return parseInt(hexString, 16);
}

export function hexToBigInt(hexString: string) {
  return BigInt("0x" + hexString);
}

export function hexToString(hex: string) {
  var str = "";

  for (var i = 0; i < hex.length; i += 2) {
    var v = parseInt(hex.substr(i, 2), 16);
    if (v) str += String.fromCharCode(v);
  }
  return str;
}

export function decimalToHex(number: number) {
  let hex = number.toString(16);
  if (hex.length % 2 === 1) hex = "0" + hex;
  return hex;
}

export function bigIntToHex(number: BigInt) {
  let hex = number.toString(16);
  if (hex.length % 2 === 1) hex = "0" + hex;
  return hex;
}

export function parseHexResponse(
  hexResponse: string,
  fields: Array<{ name: string; type: string }>,
  isArray?: boolean,
  isStruct?: boolean
) {
  let raw = hexResponse;
  const res: any = [];

  if (isArray && !isStruct) {
    raw = raw.slice(8);
  }

  while (raw.length > 0) {
    res.push({});

    for (let field of fields) {
      let dataField = null;
      // eslint-disable-next-line default-case
      switch (field.type) {
        case "bool":
          dataField = Boolean(hexToDecimal(raw.substring(0, 2)));
          raw = raw.slice(2);
          break;
        case "u8":
          dataField = hexToDecimal(raw.substring(0, 2));
          raw = raw.slice(8);
          break;
        case "u32":
          dataField = hexToDecimal(raw.substring(0, 8));
          raw = raw.slice(8);
          break;
        case "usize":
          dataField = hexToDecimal(raw.substring(0, 8));
          raw = raw.slice(8);
          break;
        case "u64":
          dataField = hexToDecimal(raw.substring(0, 16));
          raw = raw.slice(16);
          break;
        case "TokenIdentifier":
          const tokenSize = parseInt(raw.substring(0, 8), 16);
          const tokenEnd = 8 + tokenSize * 2;
          // eslint-disable-next-line no-undef
          dataField = hexToString(raw.substring(8, tokenEnd));
          raw = raw.slice(tokenEnd);
          break;
        case "BigUint":
          const sizeBytes = parseInt(raw.substring(0, 8), 16);
          const end = 8 + sizeBytes * 2;
          // eslint-disable-next-line no-undef
          dataField = BigInt("0x" + (raw.substring(8, end) || "00"));
          raw = raw.slice(end);
          break;
      }

      res[res.length - 1][field.name] = dataField;
    }
  }

  return isArray ? res : res[0];
}
