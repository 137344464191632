import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Layout from "./components/Layout";
import { network } from "./config";
import "./App.css";
import { DappProvider } from "@elrondnetwork/dapp-core/wrappers";

import { TransactionsToastList, SignTransactionsModals, NotificationModal } from "@elrondnetwork/dapp-core/UI";

function App() {
  return (
    <BrowserRouter>
      <DappProvider environment={network.id} customNetworkConfig={{ name: "customConfig", apiTimeout: 6000 }}>
        <Layout>
          <TransactionsToastList />
          <NotificationModal />
          <SignTransactionsModals />

          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Layout>
      </DappProvider>
    </BrowserRouter>
  );
}

export default App;
