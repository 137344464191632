export const contractAddress = "erd1qqqqqqqqqqqqqpgqk0wmy8ylcjx46ammmcwqqwqpklud7pdy942q463znn";
export const gasLimit = "10000000";
export const collection = "VLMEXICO1-dbc22f";
export const totalSupply = 1000;

export const tokens = [
  {
    name: "$EGLD",
    id: "EGLD",
    denominate: 18,
    noTransfer: true
  },
  {
    name: "$USDC",
    id: "USDC-c76f1f",
    denominate: 6
  }
];

export const network = {
  id: "mainnet",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "https://explorer.elrond.com/"
};
