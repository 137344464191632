import { Button, ButtonGroup, Col, Container, FormControl, InputGroup, ProgressBar, Row } from "react-bootstrap";
import "./Home.css";
import MidImage from "../assets/midsection.png";
import { useEffect, useState } from "react";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core/hooks";
import { getCollection, getInfo, getPrices, getWalletInfo, mint } from "../services/api";
import { tokens, totalSupply } from "../config";
import { formatAmount } from "../services/utils";

const Home = () => {
  const { account } = useGetAccountInfo();
  const [balance, setBalance] = useState("-1");
  const [walletInfo, setWalletInfo] = useState<any>({});
  const [info, setInfo] = useState<any>({ pricePerUnit: BigInt(0) });
  const [amount, setAmount] = useState(1);
  const [collection, setCollection] = useState<any[]>([]);

  const [currency, setCurrency] = useState(0);
  const [prices, setPrices] = useState<any>([]);

  useEffect(() => {
    if (account.balance === balance) {
      return;
    }

    getInfo().then(res => setInfo(res));
    getPrices().then(res => setPrices(res));

    if (!account.address) return;

    getWalletInfo(account.address).then(res => setWalletInfo(res));
    getCollection(account.address).then(res => setCollection(res));

    setBalance(account.balance);
  }, [account, balance]);

  const canMint = () =>
    info.soldOut ||
    info.frozen ||
    !account.address ||
    walletInfo.boughtUnits >= walletInfo.maxUnitsPerAddress ||
    (info.currentPhase === 1 && !walletInfo.inGreenList) ||
    (info.currentPhase === 2 && !walletInfo.inWhiteList && !walletInfo.inGreenList);

  const getDisabledMessage = () => {
    if (info.soldOut) return "Sold Out!";
    if (info.frozen) return "Smart Contract Blocked";
    if (!account.address) return "Connect your wallet to buy!";
    if (walletInfo.boughtUnits >= walletInfo.maxUnitsPerAddress) return "You can't buy anymore!";
    if (info.currentPhase === 1 && !walletInfo.inGreenList) return "Not in Green List";
    if (info.currentPhase === 2 && !walletInfo.inWhiteList && !walletInfo.inGreenList) return "Not in Green or White List";
    return "";
  };

  return (
    <>
      <Container className="section-1 text-center">
        <h1 className="mt-1">VictoriaLand Mexico I NFT Collection</h1>
        <p>First drop of 1,000 NFTs directly linked to the protection of a 40-hectare desert ecosystem in Mexico</p>
      </Container>

      <Container className="section-2 text-center">
        <h2>MINTING OPEN</h2>
        <p>
          Until SEPTEMBER 12th <strong>only GreenListed</strong> users can buy<br></br>From SEPTEMBER 12th to SEPTEMBER 14th{" "}
          <strong>GreenListed and WhiteListed</strong> users will be able to buy<br></br> From SEPTEMBER 14th minting will be{" "}
          <strong>open for everyone</strong>
        </p>
      </Container>

      <div className="section-3">
        <Container fluid className="max-width">
          <Row>
            <Col xs="12" lg="6" className="d-flex justify-content-center align-items-center">
              <img style={{ width: "100%" }} alt="MidImage" src={MidImage} className="mid-image" />
            </Col>
            <Col xs="12" lg="6" className="stats pt-2">
              <div className="mb-4">
                <h2 className="m-0">The Mexico I NFT</h2>
                <p className="nft-price">
                  {tokens.map((token, index) => (
                    <span key={index}>
                      {formatAmount(prices[index], token.denominate)} {token.name} {index !== tokens.length - 1 && "/ "}
                    </span>
                  ))}
                </p>
              </div>

              {account.address && (
                <>
                  <div className="separator"></div>
                  <p className="mt-4 mb-2">
                    You minted <span className="bold">{walletInfo.boughtUnits}</span> out of{" "}
                    <span className="bold">{walletInfo.maxUnitsPerAddress}</span>
                  </p>
                  <div className="d-flex flex-wrap mb-4">
                    {Array(walletInfo.maxUnitsPerAddress)
                      .fill(0)
                      .map((_, index) => (
                        <div
                          key={index}
                          className={`mint me-1 mb-1 ${
                            index < walletInfo.boughtUnits ? "minted" : index < walletInfo.boughtUnits + amount ? "selected" : ""
                          }`}
                        ></div>
                      ))}
                  </div>
                  <div className="separator"></div>
                </>
              )}

              <div className="d-flex align-items-center mt-3">
                <p className="m-0 me-2">I want to pay in:</p>
                <ButtonGroup className="pay-in">
                  {tokens.map((token, index) => (
                    <Button key={index} onClick={() => setCurrency(index)} className={currency === index ? "selected" : "no-selected"}>
                      {token.name}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div className="mt-2 d-flex align-items-center">
                <p className="me-2">Amount: </p>
                <InputGroup className="mb-3">
                  <Button disabled={canMint()} variant="transparent" id="button-addon1" onClick={() => setAmount(Math.max(1, amount - 1))}>
                    -
                  </Button>
                  <FormControl
                    type="number"
                    disabled={canMint()}
                    value={amount}
                    onChange={e => {
                      if (!walletInfo.maxUnitsPerAddress) {
                        setAmount(Math.max(1, Number(e.target.value)));
                        return;
                      }

                      setAmount(Math.max(1, Math.min(walletInfo.maxUnitsPerAddress - walletInfo.boughtUnits, Number(e.target.value))));
                    }}
                  />

                  <Button
                    disabled={canMint()}
                    variant="transparent"
                    onClick={() => {
                      if (!walletInfo.maxUnitsPerAddress) {
                        setAmount(amount + 1);
                        return;
                      }

                      setAmount(Math.min(walletInfo.maxUnitsPerAddress - walletInfo.boughtUnits, amount + 1));
                    }}
                  >
                    +
                  </Button>
                </InputGroup>
              </div>
              <p className="mb-3 mt-3">
                Total price:{" "}
                <span className="medium">
                  {prices[currency] && formatAmount(BigInt(amount) * prices[currency], tokens[currency].denominate)} {tokens[currency].name}
                </span>
              </p>
              <div className="d-grid">
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() => mint(amount, BigInt(amount) * prices[currency], tokens[currency])}
                  disabled={canMint()}
                >
                  MINT
                </Button>
                <div className="mt-2">
                  <p>{getDisabledMessage()}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="my-collection">
        <Container fluid className=" max-width py-4">
          {account.address && Boolean(collection.length) && (
            <>
              <Row className="pb-4 text-center">
                <h1>My collection</h1>
              </Row>
              <div className="d-flex justify-content-center">
                <div className="separator"></div>
              </div>
              <Row className="pb-5">
                {collection.map(nft => (
                  <Col xs="12" md="6" lg="4" key={nft.identifier} className="mt-4">
                    <img style={{ width: "100%" }} alt="Cover" src={nft.media[0].url} />

                    <div className="collection-tags mt-3">
                      <div className="mt-3 d-flex flex-wrap">
                        {nft.tags.map((tag: string) => (
                          <span key={tag} className="pill bg-secondary me-2 mb-2">
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default Home;
