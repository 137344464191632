import { sendTransactions } from "@elrondnetwork/dapp-core/services";
import { Address, AddressValue, ContractFunction, SmartContract, TokenIdentifierValue } from "@elrondnetwork/erdjs/out";
import { collection, contractAddress, gasLimit, network } from "../config";
import { bigIntToHex, bufferToHex, decimalToHex, hexToString, parseHexResponse, stringToHex } from "./utils";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { tokens } from "../config";

let provider: ProxyNetworkProvider;
let contract: SmartContract;

async function initApi() {
  contract = new SmartContract({
    address: new Address(contractAddress)
  });

  provider = new ProxyNetworkProvider(network.gatewayAddress);
}

async function queryContractFunction(functionName: string, address?: string | null, args?: any[]) {
  if (!contract) {
    await initApi();
  }

  const options: any = {
    func: new ContractFunction(functionName)
  };

  if (address) {
    options.args = [new AddressValue(new Address(address))];
  }

  if (args) {
    if (options.args) {
      options.args.push(...args);
    } else {
      options.args = args;
    }
  }

  const query = contract.createQuery(options);
  return await provider.queryContract(query);
}

export function getPrices() {
  return Promise.all(
    tokens.map(token => {
      return queryContractFunction("get_price", null, [new TokenIdentifierValue(token.id)]).then((res: any) =>
        BigInt("0x" + (bufferToHex(res.returnData[0]) || "0"))
      );
    })
  );
}

export async function getInfo() {
  const res = await queryContractFunction("summary_info");
  return parseHexResponse(bufferToHex(res.returnData[0]), [
    { name: "frozen", type: "bool" },
    { name: "pricePerUnit", type: "BigUint" },
    { name: "soldOut", type: "bool" },
    { name: "availableNFTCount", type: "u32" },
    { name: "currentPhase", type: "u8" }
  ]);
}

export async function getWalletInfo(address: string) {
  const res = await queryContractFunction("wallet_summary_info", address);
  return parseHexResponse(bufferToHex(res.returnData[0]), [
    { name: "inGreenList", type: "bool" },
    { name: "inWhiteList", type: "bool" },
    { name: "maxUnitsPerAddress", type: "u32" },
    { name: "boughtUnits", type: "u32" }
  ]);
}

export async function mint(quantity: number, price: BigInt, token: any) {
  let transaction;
  if (token.noTransfer) {
    transaction = {
      receiver: contractAddress,
      data: "buy@" + decimalToHex(quantity),
      value: price,
      gasLimit: Number(gasLimit) + 3000000 * quantity
    };
  } else {
    transaction = {
      receiver: contractAddress,
      data: ["ESDTTransfer", stringToHex(token.id), bigIntToHex(price), stringToHex("buy"), decimalToHex(quantity)].join("@"),
      value: "0",
      gasLimit: Number(gasLimit) + 3000000 * quantity
    };
  }

  sendTransactions({
    transactions: [transaction]
  });
}

export async function getCollection(address: string): Promise<any[]> {
  const url = `${network.apiAddress}/accounts/${address}/nfts?collections=${collection}&size=50`;
  const res = await fetch(url);
  const nfts = await res.json();
  nfts.forEach((nft: any) => {
    nft.attributes = hexToString(bufferToHex(nft.attributes))
      .split(";")
      .reduce((acc, next) => ({ ...acc, [next.split(":")[0].replaceAll(" ", "")]: next.split(":")[1] }), {});
  });
  return nfts;
}
